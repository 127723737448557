import { Template } from '../template.model';
import { TemplateActions, TemplateActionTypes } from './template.actions';
import { CallingState } from '../../state/app.state';

export interface TemplateState {
  templates: Template[];
  currentTemplateIdBeingEdited: string;
  callState: CallingState;
  templateIdCreated: string;
}

const initialState: TemplateState = {
  templates: [],
  currentTemplateIdBeingEdited: '',
  callState: CallingState.INIT,
  templateIdCreated: ''
};

export function templateReducer(state = initialState, action: TemplateActions): TemplateState {

  switch (action.type) {
    case TemplateActionTypes.LOAD_TEMPLATES:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case TemplateActionTypes.LOAD_TEMPLATES_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        templates: action.templates
      };
    case TemplateActionTypes.LOAD_TEMPLATES_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case TemplateActionTypes.SET_TEMPLATE_ID_BEING_EDITED:
      return {
        ...state,
        currentTemplateIdBeingEdited: action.templateId
      };
    case TemplateActionTypes.CREATE_TEMPLATE:
      return {
        ...state,
        templateIdCreated: ''
      };
    case TemplateActionTypes.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateIdCreated: action.template.templateId,
        templates: AddTemplate(state.templates, action.template)
      };
    case TemplateActionTypes.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state
      };
    case TemplateActionTypes.SAVE_TEMPLATE:
      return {
        ...state,
        callState: CallingState.CALLING
      }
    case TemplateActionTypes.SAVE_TEMPLATE_SUCCESS:
    case TemplateActionTypes.SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      }
    default: {
      return state;
    }
  }
}

const AddTemplate = (templates: Template[], template: Template): Template [] => {
  const updatedTemplates = [...templates];
  updatedTemplates.push(template);
  return updatedTemplates;
}
