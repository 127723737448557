import { Action } from '@ngrx/store';
import { QuestionType } from '../questions.model';

export enum QuestionsActionTypes {
  SET_QUESTION_OPTIONS = '[Question] Set Question Options',
  GET_QUESTION_OPTIONS = '[Question] Get Question Options',
  SET_QUESTIONS = '[Question] Set Questions',
  CLEAR_QUESTIONS = '[Question] Clear Questions',
  ADD_QUESTION = '[Question] Add Question',
  UPDATE_QUESTION = '[Question] Update Question',
  SORT_QUESTION = '[Question] Sort Question',
  REMOVE_QUESTION = '[Question] Remove Question'
}

export class SetQuestionOptions implements Action {
  readonly type = QuestionsActionTypes.SET_QUESTION_OPTIONS;

  constructor(public payload: QuestionType[]) {
  }
}

export class GetQuestionOptions implements Action {
  readonly type = QuestionsActionTypes.GET_QUESTION_OPTIONS;
}

export class SetQuestions implements Action {
  readonly type = QuestionsActionTypes.SET_QUESTIONS;

    constructor(public questions: QuestionType[] | undefined) {
  }
}

export class ClearQuestions implements Action {
  readonly type = QuestionsActionTypes.CLEAR_QUESTIONS;
}

export class AddQuestion implements Action {
  readonly type = QuestionsActionTypes.ADD_QUESTION;

  constructor(readonly payload: { type: QuestionType, prevIndex: number, currentIndex: number }) {
  }
}

export class UpdateQuestion implements Action {
  readonly type = QuestionsActionTypes.UPDATE_QUESTION;

  constructor(readonly payload: { question: QuestionType }) {
  }
}

export class SortQuestion implements Action {
  readonly type = QuestionsActionTypes.SORT_QUESTION;

  constructor(readonly payload: { prevIndex: number, currentIndex: number }) {
  }
}

export class RemoveQuestion implements Action {
  readonly type = QuestionsActionTypes.REMOVE_QUESTION;

  constructor(public payload: { index: number }) {
  }
}

export type QuestionsActions =
  SetQuestionOptions
  | GetQuestionOptions
  | SetQuestions
  | ClearQuestions
  | AddQuestion
  | UpdateQuestion
  | SortQuestion
  | RemoveQuestion;



